<!--
 * @Author: LXG
 * @Date: 2021-04-27
 * @Editors: LXG
 * @LastEditTime: 2021-06-15
 * @Description: 政策项目申报 - 申请企业信息
-->
<template>
    <div class="enterprise">
        <h2 class="wrap-title">申请企业（法人）信息</h2>
        <div>
            <el-form
                ref="form"
                :model="cpu_value"
                size="medium"
                label-width="100px"
                :rules="formRules"
            >
                <el-form-item
                    prop="unitname"
                    label="企业名称"
                >
                    <FormInput
                        v-model="cpu_value.unitname"
                        disabled
                    ></FormInput>
                </el-form-item>
                <el-form-item
                    prop="unitcardcode"
                    label="企业证件号码"
                >
                    <FormInput
                        v-model="cpu_value.unitcardcode"
                        disabled
                    >
                        <template v-slot:prepend>
                            <span>统一社会信用代码</span>
                        </template>
                    </FormInput>
                </el-form-item>
                <el-form-item
                    prop="unitaddress"
                    label="企业地址"
                >
                    <FormInput
                        v-model="cpu_value.unitaddress"
                        disabled
                    ></FormInput>
                </el-form-item>
                <el-form-item
                    prop="legalperson"
                    label="法人姓名"
                >
                    <FormInput
                        v-model="cpu_value.legalperson"
                        disabled
                    ></FormInput>
                </el-form-item>
                <!-- <el-form-item
                    prop="legalcardnum"
                    label="法人证件号码"
                >
                    <FormInput
                        v-model="cpu_value.legalcardnum"
                        placeholder=" "
                        :disabled="disabled"
                    >
                        <template v-slot:prepend>
                            <span>身份证</span>
                        </template>
                    </FormInput>
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>

<script>
import { FormInput } from '@/components/form/index.js'
import { checkIdcard } from '@/utils/check.js'

export default {
    name: 'Enterprise',
    components: {
        FormInput,
    },
    props: {
        value: {},
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            formRules: {
                legalcardnum: [
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if (!checkIdcard(value)) {
                                    callback(new Error('请输入正确的身份证号码'))
                                } else {
                                    callback()
                                }
                            } else {
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ],
            },
        }
    },
    computed: {
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.wrap-title {
    color: #000000;
    padding-bottom: 5px;
    padding-left: 10px;
    border-bottom: 4px solid #000000;
    margin-bottom: 10px;
}
</style>