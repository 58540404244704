<template>
  <div class="test-form" :key="refreshKey">
    <parser :form-conf="formData" @submit="sumbitForm1" ref="paeserChild" />
    <!-- <div slot="footer" class="bottom-btns">
      <el-button @click="checkForm"> 填写检查 </el-button>
      <el-button type="primary" @click="sumbitForm2"> 提交 </el-button>
    </div> -->
  </div>
</template>

<script>
import Parser from "./Parser";
export default {
  name: "ParseFormView",
  components: {
    Parser,
  },
  props: ["formData", "readonly"],
  data() {
    return {
      refreshKey: +new Date(),
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // 表单数据回填，模拟异步请求场景
    // setTimeout(() => {
    //   // 默认数据
    //   const data = {
    //     username: "李四",
    //     mobile: "17683745190",
    //     content: "测试内容",
    //     itemCode: 1,
    //     deptCode: 2,
    //   };
    //   // 回填数据
    //   this.fillFormData(this.formData, data);
    // this.refreshKey = +new Date();
    // }, 1000);
    if (this.formData) {
      if (this.readonly) {
        this.formData.fields.forEach((item) => {
          item.readonly = true;
          item.disabled = true;
        });
      }
    }
  },
  methods: {
    // 可主动获取表单数据
    getFormData() {
      return new Promise((resolve, reject) => {
        this.$refs.paeserChild.getFormData().then((data) => {
          console.log("getFormData:", data);
          resolve(data);
        });
      });
    },
    getTempSaveData() {
      //获取暂存数据 不进行校验
      return new Promise((resolve, reject) => {
        this.$refs.paeserChild.getTempSaveData().then((data) => {
          resolve(data);
        });
      });
    },
    // 给表单填充数据
    fillFormData(form, data) {
      console.log("给表单填充数据>>>>>>>", form, data)
      for (let i = 0; i < form.fields.length; i++) {
        const item = form.fields[i];
        let val  
        if (item.align) {
          let  list = item.__config__.children 
          for (let i = 0; i < list.length; i++ ) {
            const el = list[i]
            val = data[el.__vModel__];
            if (val) {
              el.__config__.defaultValue = val;
            }
          }
        } else {
          val = data[item.__vModel__];
          if (val) {
            item.__config__.defaultValue = val;
          }
        }
      }
      this.refreshKey = +new Date();
    },
    //子组件回调父组件的方法
    sumbitForm1(data) {
      // console.log("-提交数据-", data);
      this.$emit("submitForm", data);
    },
    //父组件调子组件的方法
    sumbitForm2() {
      /*
       点击提交按钮的时候调用paeserChild.submitForm方法，
       此方法会检查规则 然后调用父类的方法sumbitForm1并把数据传回来
      */
      this.$refs.paeserChild.submitForm();
    },
    checkForm() {
      console.log("填写检查");
    },
  },
};
</script>

<style lang="scss" scoped>
.test-form {
  margin: 15px auto;
  width: 100%;
  padding-right: 15px;
}
.bottom-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
